.cert-list-container {
    margin: 2em 0;
}

table {
    background: #fff;
    border: 1px solid lightgray !important;
}

.connected {
    color: green;
    font-weight: 400;
}

.not-connected {
    color: red;
    font-weight: 400;
}

.unknown-state {
    color: black;
    font-weight: 400;
}