/* Please note that Proxima Vara is an aggregated version of Proxima Nova, containing all of its 
weights and styles in a single font file: https://www.marksimonson.com/fonts/view/proxima-vara/

/* Title/Hero */
@font-face {
  font-family: 'Proxima Nova 32 Extrabold';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  src: local('ProximaVara'),
       url('ProximaVara.woff') format('woff');
}

/* Heading 1 */
@font-face {
  font-family: 'Proxima Nova 24 Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  src: local('ProximaVara'),
       url('ProximaVara.woff') format('woff');
}

/* Heading 2 */
@font-face {
  font-family: 'Proxima Nova 20 Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  src: local('ProximaVara'),
       url('ProximaVara.woff') format('woff');
}

/* Heading 3 */
@font-face {
  font-family: 'Proxima Nova 16 Extrabold';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  src: local('ProximaVara'),
       url('ProximaVara.woff') format('woff');
}

/* Heading 4 */
@font-face {
  font-family: 'Proxima Nova 14 Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  src: local('ProximaVara'),
       url('ProximaVara.woff') format('woff');
}

/* Sidebar, Buttons */
@font-face {
  font-family: 'Proxima Nova 16 Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  src: local('ProximaVara'),
       url('ProximaVara.woff') format('woff');
}

/* Body */
@font-face {
  font-family: 'Proxima Nova 12 Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  src: local('ProximaVara'),
       url('ProximaVara.woff') format('woff');
}

/* Hint/Caption */
@font-face {
  font-family: 'Proxima Nova 10 Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  src: local('ProximaVara'),
       url('ProximaVara.woff') format('woff');
}

/* Emphasis */
@font-face {
  font-family: 'Proxima Nova 12 Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  src: local('ProximaVara'),
       url('ProximaVara.woff') format('woff');
}

/* Link */
@font-face {
  font-family: 'Proxima Nova 12 Regular Underline';
  font-style: normal;
  font-weight: normal;
  text-decoration: underline;
  font-size: 12px;
  src: local('ProximaVara'),
       url('ProximaVara.woff') format('woff');
}